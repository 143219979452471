import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <>
    <footer>
        <p className='made-in-message'>made with ❤️ in Toronto, Canada</p>
    </footer>
   
    </> 
  )
}

export default Footer
