import React from 'react';
import './HeroSection.css';

function HeroSection() {
  return (
    <>
    <p className='welcome-message'>hey, thanks for being here!</p>
    </> 
  )
}

export default HeroSection
