import React from 'react';
import './Navbar.css';

function Navbar() {
  return (
    <>     
      <nav className="navbar">
          <p className="navbar-logo">twinkle mehta</p>
      </nav>
    </> 
  )
}

export default Navbar
